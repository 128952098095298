import './App.css';
import Hero from './components/Hero/Hero';
import { Plans } from './components/plans/Plans';
import { Program } from './components/Programs/Program';
import { Reasons } from './components/resons/Reasons';
import { Testimonials } from './components/testimonials/Testimonials';
import { Join } from './components/join/Join';
import { Footer } from './components/footer/Footer';

function App() {
  return (
    <div className="App">
      <Hero/>       
      <Program/>
      <Reasons/>
      <Plans/>
      <Testimonials/>
      <Join/>
      <Footer/>
    </div>
  );
}

export default App;

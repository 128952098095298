import React from 'react'
import whiteTick from '../.././assets/whiteTick.png';
import {plansData} from '../../data/plansData'
import './Plans.css'
export const Plans = () => {
  return (
    <div className="plans-container" id='plan'>
        <div className="blur plans-blur-1"></div>
        <div className="blur plans-blur-2 "></div>
        <div className="programs-header" style={{gap:"3rem"}}>
            <span className='stroke-text'>Ready to start</span>
            <span>your journy</span>
            <span className='stroke-text'>now withus</span>
        </div>
       
        {/* Plans card */}
        <div className="plans">
            {plansData.map((plan, i)=>(
                <div className="plan" key={i}>
                    {plan.icon}
                    <span>{plan.name}</span>
                    <span>{plan.price}</span>
                   
                    <div className="features">
                        {plan.features.map((feature, i)=>(
                            <div className="feature">
                                <img src={whiteTick} alt="" />
                                <span key={i}>{feature}</span>
                            </div>
                        ))}
                    </div>
                    <div>
                        <span>See more benofits -> </span>
                    </div>
                    <button className='btn'>Join Now</button>
                </div>
            ))}
        </div>

    </div>
  );
};

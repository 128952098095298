import React, { useRef } from 'react';
// import emailjs from '@emailjs/browser';
import './Join.css';


export const Join = () => {
  const form = useRef();

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs.sendForm('service_5r0yfrb', 'template_7p67hrj', form.current, 'jvNruNrBzeTyHhK5Y')
  //     .then((result) => {
  //         console.log(result.text);
  //     }, (error) => {
  //         console.log(error.text);
  //     });
  // };

  return (
    <div className="join" id="join">
        <div className="left-j">
            <hr />
            <div>
                <span className='stroke-text'>READY TO  </span>
                <span>LEVEL UP</span>
            </div>
            <div>
                <span className='stroke-text'>YOUR BODY </span>
                <span>WITH US</span>
            </div>
        </div>
        <div className="right-j">

          <form ref={form} className="email-container" >
             {/* onSubmit={sendEmail} */}
            <input type="email" name='user_email' placeholder='Enter Your Email Address' />
            <button className='btn btn-j'>Join Now</button>
          </form>
        </div>
    </div>
  )
}

import React from 'react'
import Header from '../Header/Header'
import heart from '../.././assets/heart.png'
import heroimage from '../.././assets/heroimage.png'
import hero_image_back from '../.././assets/hero_image_back.png'
import calories from '../.././assets/calories.png'
import NumberCounter from 'number-counter'
import './Hero.css'

import {motion} from 'framer-motion'

const Hero = () => {
    const transition = {type:'spring', duration:3}
    const mobile = window,innerWidth=768? true: false;
  return (
   <div className="hero" id='home'>
    <div className="blur hero-blur"></div>
    <div className="left-h">
    <Header/>
{/* The best add */}
    <div className="the-best-ad">
    <motion.div 
    initial={{left:mobile? "160px":'238px'}}
    whileInView={{left:'8px'}}
    transition={{...transition,type:'tween'}}
    ></motion.div>
    <span>The best fitness club in the town</span>
    </div>
    
    {/* Headding */}
            <div className="hero-text">
                <div>
                    <span className='stroke-text'>Shape </span>
                    <span>Your</span>
             </div>
        <div>
            <span>Ideal Body</span>
        </div>
            <div>
                <span>In Here we will help you to shape and build your idea live up your life to fullest</span>
            </div>
        </div>
        {/* Figurs */}
        <div className="figure">
            <div>
            <span><NumberCounter end={140} start={100} delay='4' preFix="+"/>
            </span>
            <span>expert cotches</span>
            </div>
            <div>
            <span>
            <NumberCounter end={978} start={800} delay='4.5' preFix="+"/>
            </span>
            <span>members joined</span>
            </div>
            <div>
            <span>
                <NumberCounter end={50} start={1} delay='1.5' preFix="+"/></span>
            <span>fitness programs</span>
            </div>
        </div>

        {/* Hero buttons */}

        <div className="hero-buttons">
            <button className="btn">Get started</button>
            <button className="btn">Learn More</button>
        </div>
     </div>
            <div className="right-h">
                <button className="btn">Join Now</button>

                <motion.div
                 initial={{ right: '-1rem'}}
                 whileInView={{ right:'4rem'}}
                 transition={{...transition,type:'spring'}}
                className="heart-rate">
                    <img src={heart} alt=""/>
                    <span>Heart Rate</span>
                    <span>116 bps</span>
                </motion.div>
{/* hero images */}

        <img src={heroimage}alt=""className="hero-image" />
        <motion.img
                 initial={{ right: '11rem'}}
                 whileInView={{ right:'22rem'}}
                 transition={{...transition}}
        src={hero_image_back} alt="" className="hero-image-back" />

        <motion.div 
         initial={{ right: '37rem'}}
         whileInView={{ right:'30rem'}}
         transition={{...transition}}
        className='calories'>
            <img src={calories} alt="" />
     <div>
     <span>Calories Burned</span>
     <span>220 kcal</span>
     </div>
            </motion.div>
            </div>
   </div>
  )
}

export default Hero